/* global cornerstone */
import PropTypes from 'prop-types';
import React from 'react';
import { useIntersection } from 'react-use';
import ShouldPreloadContext from '../../utils/ShouldPreloadContext';
import UseHook from '../../utils/UseHook';
import ViewportContext from '../../utils/ViewportContext';
import ViewportErrorIndicator from '../../viewer/ViewportErrorIndicator';
import ViewportLoadingIndicator from '../../viewer/ViewportLoadingIndicator';
import './ImageThumbnail.styl';

// TODO: How should we have this component depend on Cornerstone?
// - Passed in as a prop?
// - Set as external dependency?
// - Pass in the entire load and render function as a prop?
//import cornerstone from 'cornerstone-core';

const ImageThumbnail = (props) => {
  const canvas = React.useRef(null);
  const shouldRenderToCanvas = props.imageId && !props.imageSrc;

  // Should we preload images even if the canvas isn't in the viewport
  const shouldPreloadFromContext = React.useContext(ShouldPreloadContext);
  const shouldPreload = shouldPreloadFromContext || props.preload;

  // Scroll viewport
  const viewport = React.useContext(ViewportContext);
  const [intersection, setIntersection] = React.useState(null);
  const intersectionRatio = intersection && intersection.intersectionRatio;

  // Status: init | loading | done | error
  const [status, setStatus] = React.useState('init');

  // loads all thumbnails before loading images.
  //
  React.useEffect(() => {
    if (status !== 'init') return;

    if (!shouldPreload && intersectionRatio <= 0) return;

    if (!shouldRenderToCanvas) {
      setStatus('done');
      return;
    }

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    );
    if (isMobile) {
      setStatus('done');
      return;
    }

    setStatus('loading');

    cornerstone
      .loadAndCacheImage(props.imageId)
      .then((image) => {
        cornerstone.renderToCanvas(canvas.current, image);

        setStatus('done');
      })
      .catch((error) => {
        setStatus('error');
        throw new Error(error);
      });
  }, [intersectionRatio, props.preload]);

  let loadingOrError;
  if (props.error || status === 'error') {
    loadingOrError = <ViewportErrorIndicator />;
  } else if (status !== 'done') {
    loadingOrError = <ViewportLoadingIndicator />;
  }

  return (
    <>
      {status === 'init' && (
        <UseHook
          hook={useIntersection}
          args={[
            canvas,
            {
              root: viewport,
              rootMargin: '0px',
              threshold: 0.01,
            },
          ]}
          onValue={setIntersection}
        />
      )}
      <div className="ImageThumbnail">
        <div className="image-thumbnail-canvas">
          {shouldRenderToCanvas ? (
            <canvas ref={canvas} width={props.width} height={props.height} />
          ) : (
              <img
                className="static-image"
                src={props.imageSrc}
                //width={props.width}
                height={props.height}
                alt={''}
              />
            )}
        </div>
        {loadingOrError}
        <div className="image-thumbnail-progress-bar">
          <div
            className="image-thumbnail-progress-bar-inner"
            style={{ width: `${props.stackPercentComplete}%` }}
          />
        </div>
        {status === 'loading' && <div className="image-thumbnail-loading-indicator"></div>}
      </div>
    </>
  );
};
ImageThumbnail.propTypes = {
  imageSrc: PropTypes.string,
  imageId: PropTypes.string,
  error: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  stackPercentComplete: PropTypes.number.isRequired,
  preload: PropTypes.bool,
};
ImageThumbnail.defaultProps = {
  error: false,
  stackPercentComplete: 0,
  width: 217,
  height: 123,
};
export default ImageThumbnail;
