import './toolbar-button.styl';

import { Icon } from './../elements/Icon';
import { Icon as IconAntd, notification } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { withTranslation } from '../utils/LanguageProvider';

export function ToolbarButton(props) {
  const { isActive, isMp4Viewer, icon, labelWhenActive, onClick, t } = props;
  const className = classnames(props.className, { active: isActive });
  const iconProps = typeof icon === 'string' ? { name: icon } : icon;
  const label = isActive && labelWhenActive ? labelWhenActive : props.label;

  const arrowIconName = props.isExpanded ? 'caret-up' : 'caret-down';
  const arrowIcon = props.isExpandable && <Icon name={arrowIconName} className="expand-caret" />;
  const styling = isMp4Viewer ? { cursor: 'not-allowed', opacity: '0.5' } : {};

  const handleClick = (event) => {
    if (isMp4Viewer) {
      notification.open({
        message: <div style={{color: 'rgb(85, 0, 0)'}}><b>Incompatible Viewer</b></div>,
        description: 'You must be using the Measurement Viewer to use this tool. Select "Use Measurement Viewer" from the toolbar.',
        style: {background: 'white', color: 'rgb(85, 0, 0)'},
        icon: <IconAntd type='star' />
      });
    } else if (onClick) {
      onClick(event, props);
    }
  };

  return (
    <div>
      <div className={className} style={styling} onClick={handleClick}>
        {iconProps && <Icon {...iconProps} />}
        <div className="toolbar-button-label">
          {t(label)}
          {arrowIcon}
        </div>
      </div>
    </div>
  );
}

ToolbarButton.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  /** Display label for button */
  label: PropTypes.string.isRequired,
  /** Alternative text to show when button is active */
  labelWhenActive: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]),
  onClick: PropTypes.func,
  /** Determines if we show expandable 'caret' symbol */
  isExpandable: PropTypes.bool,
  /** Direction of expandable 'caret' symbol */
  isExpanded: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ToolbarButton.defaultProps = {
  isActive: false,
  className: 'toolbar-button',
};

export default withTranslation('Buttons')(ToolbarButton);
