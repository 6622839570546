import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ViewportContext from '../../utils/ViewportContext';
import './StudyBrowser.styl';
import { isMp4Viewer } from '@ohif/ui/utils/ConversionStatus';
import { ThumbnailEntry } from './ThumbnailEntry';
import ThumbnailEntryDragSource from './ThumbnailEntryDragSource.js';

class StudyBrowser extends Component {
  static defaultProps = {
    studies: [],
    supportsDragAndDrop: true,
  };

  static propTypes = {
    studies: PropTypes.array.isRequired,
    supportsDragAndDrop: PropTypes.bool.isRequired,
    onThumbnailClick: PropTypes.func,
    onThumbnailDoubleClick: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      scrollViewport: null,
    };
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    this.scrollViewportRefCallback = (scrollViewport) => {
      this.setState({
        scrollViewport,
      });
    };
  }

  render() {
    const studies = this.props.studies;
    const thumbnails = studies.map((study, studyIndex) => {
      const showStackPercentComplete = !isMp4Viewer(this.props.studyConversionStatus[study.studyInstanceUid]);

      return study.thumbnails.map((thumb, thumbIndex) => {
        if (this.props.supportsDragAndDrop) {
          const stackPercentProgress = this.props.loading.progress[thumb.displaySetInstanceUid];
          const stackPercentComplete = (showStackPercentComplete && stackPercentProgress) ? stackPercentProgress.percentComplete : 0;

          return (
            <ThumbnailEntryDragSource
              key={thumb.displaySetInstanceUid}
              {...study}
              {...thumb}
              index={thumbIndex}
              id={`${studyIndex}_${thumbIndex}`}
              stackPercentComplete={stackPercentComplete}
              onClick={this.props.onThumbnailClick}
              onDoubleClick={this.props.onThumbnailDoubleClick}
            />
          );
        } else {
          return (
            <div className="ThumbnailEntryContainer" data-cy="thumbnail-list">
              <ThumbnailEntry
                key={thumb.displaySetInstanceUid}
                {...study}
                {...thumb}
                index={thumbIndex}
                id={`${studyIndex}_${thumbIndex}`}
                onClick={this.props.onThumbnailClick}
                onDoubleClick={this.props.onThumbnailDoubleClick}
              />
            </div>
          );
        }
      });
    });

    const components = thumbnails.flat();
    return (
      <ViewportContext.Provider value={this.state.scrollViewport}>
        <div className="StudyBrowser">
          <div
            id={`scrollable-study-thumbnails-${this.props.studyInstanceUid}`}
            className="scrollable-study-thumbnails"
            ref={this.scrollViewportRefCallback}
          >
            {components}
          </div>
        </div>
      </ViewportContext.Provider>
    );
  }
}

export { StudyBrowser };
