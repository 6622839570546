import OHIF from '@ohif/core';
import redux from '@ohif/core/redux/actions';
import store from '@ohif/viewer/store';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

const scroll = cornerstoneTools.import('util/scroll');

const actions = {
  rotateViewport: ({ viewports, rotation }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    if (enabledElement) {
      let viewport = cornerstone.getViewport(enabledElement);
      viewport.rotation += rotation;
      cornerstone.setViewport(enabledElement, viewport);
    }
  },
  flipViewportHorizontal: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    if (enabledElement) {
      let viewport = cornerstone.getViewport(enabledElement);
      viewport.hflip = !viewport.hflip;
      cornerstone.setViewport(enabledElement, viewport);
    }
  },
  flipViewportVertical: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    if (enabledElement) {
      let viewport = cornerstone.getViewport(enabledElement);
      viewport.vflip = !viewport.vflip;
      cornerstone.setViewport(enabledElement, viewport);
    }
  },
  scaleViewport: ({ viewports, direction }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );
    const step = direction * 0.15;

    if (enabledElement) {
      if (step) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.scale += step;
        cornerstone.setViewport(enabledElement, viewport);
      } else {
        cornerstone.fitToWindow(enabledElement);
      }
    }
  },
  resetViewport: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    if (enabledElement) {
      cornerstone.reset(enabledElement);
    }
  },
  invertViewport: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    if (enabledElement) {
      let viewport = cornerstone.getViewport(enabledElement);
      viewport.invert = !viewport.invert;
      cornerstone.setViewport(enabledElement, viewport);
    }
  },
  // TODO: this is receiving `evt` from `ToolbarRow`. We could use it to have
  //       better mouseButtonMask sets.
  setToolActive: ({ toolName }) => {
    if (!toolName) {
      console.warn('No toolname provided to setToolActive command');
    }
    cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
  },
  doubleClickThumbnail: (data) => {
    const activeViewportIndex = data.viewports.activeViewportIndex;
    const studyInstanceUid = locateStudyInstanceUid(data.displaySetInstanceUid);

    handleClearCacheOnPagination();

    const viewportData = data.viewports.viewportSpecificData[activeViewportIndex];

    // Add new element.
    const displaySets = OHIF.utils.studyMetadataManager.all(null).find(x => x._studyInstanceUID === studyInstanceUid)._displaySets;

    const newDisplaySet = JSON.parse(
      JSON.stringify(displaySets.find(x => x.displaySetInstanceUid === data.displaySetInstanceUid))
    );

    // Setting these fields allows us to keep track of if cine was playing when navigating images in
    // OHIFCornerstoneViewport - setStateFromProps and ConnectedCornerstoneViewport - onElementEnabled.
    newDisplaySet.cine = {};
    // We use last cine's isPlaying to determine if the cine is still playing or not.
    newDisplaySet.cine.isPlaying = (viewportData.cine && viewportData.cine.isPlaying);
    newDisplaySet.cine.cineFrameRate = (newDisplaySet.frameRate) || 30;

    //var element = _getActiveViewportEnabledElement(data.viewports.viewportSpecificData, activeViewportIndex);
    const enabledElements = cornerstone.getEnabledElements();
    if (enabledElements.length > activeViewportIndex) {
      // Remove old element.
      var element = enabledElements[activeViewportIndex].element;
      cornerstoneTools.clearToolState(element, 'stack');
      cornerstoneTools.stopClip(element);

      newDisplaySet.dom = enabledElements[activeViewportIndex].element;
    }

    store.dispatch(redux.setViewportSpecificData(activeViewportIndex, newDisplaySet));
  },
  updateNextViewportDisplaySet: _ref33 => {
    const activeTab = sessionStorage['viewerActiveTab'] || 888;

    const layoutCount = _ref33.viewports.layout.viewports.length;
    const viewportData = _ref33.viewports.viewportSpecificData;

    const displaySets = OHIF.utils.studyMetadataManager.all(null).find(x => x._studyInstanceUID === activeTab)._displaySets;

    const activeThumbnailIndexes = Object.keys(viewportData).map(function (key) {
      return displaySets.findIndex(x => x.instanceNumber === viewportData[key].instanceNumber);
    });

    const thumbnailIndexMax = Math.max(...activeThumbnailIndexes);
    const thumbnailIndexLast = displaySets.length - 1;

    const paginationOutOfBounds = ((thumbnailIndexLast - thumbnailIndexMax) < layoutCount);
    const thumbnailIndexToStartAt = (paginationOutOfBounds) ? (thumbnailIndexLast - layoutCount + 1) : thumbnailIndexMax + 1;

    // Add frames to fill the layout.
    let newDisplaySets = {};

    const enabledElements = cornerstone.getEnabledElements();
    for (let i = 0; i < layoutCount; i++) {
      const nextIndex = thumbnailIndexToStartAt + i;

      const viewportCurrent = viewportData[i];
      const viewportNext = displaySets[nextIndex];

      if (viewportCurrent.displaySetInstanceUid === viewportNext.displaySetInstanceUid) {
        newDisplaySets[i] = viewportCurrent;
        continue;
      }

      const newDisplaySet = JSON.parse(JSON.stringify(viewportNext));

      if (enabledElements.length > i) {
        var element = enabledElements[i].element;
        //var element = _getActiveViewportEnabledElement(viewportData, i);

        cornerstoneTools.stopClip(element);
        cornerstoneTools.clearToolState(element, 'stack');

        newDisplaySet.dom = element;
      }

      // Setting these fields allows us to keep track of if cine was playing when navigating images in
      // OHIFCornerstoneViewport - setStateFromProps and ConnectedCornerstoneViewport - onElementEnabled.
      newDisplaySet.cine = {};
      // We use last cine's isPlaying to determine if the cine is still playing or not.
      newDisplaySet.cine.isPlaying = (viewportCurrent.cine && viewportCurrent.cine.isPlaying);
      newDisplaySet.cine.cineFrameRate = (newDisplaySet.frameRate) || 30;

      newDisplaySets[i] = newDisplaySet;
    }

    const newLayout = {
      numRows: _ref33.viewports.numRows,
      numColumns: _ref33.viewports.numColumns,
      viewports: _ref33.viewports.layout.viewports,
    };

    // Fix for going next on last instance.
    //
    // Only execute if we have new frames to add.
    if (Object.keys(newDisplaySets).length > 0) {
      handleClearCacheOnPagination();

      // Scroll to active thumbnail on sidebar.
      document.getElementById(`scrollable-study-thumbnails-${activeTab}`).scrollTop =
        document.getElementById(newDisplaySets[0].displaySetInstanceUid)
          .offsetTop - 87.5;

      store.dispatch(redux.setViewportLayoutAndData(newLayout, newDisplaySets));
    }
  },
  updatePreviousViewportDisplaySet: _ref34 => {
    const activeTab = sessionStorage['viewerActiveTab'] || 888;

    const layoutCount = _ref34.viewports.layout.viewports.length;
    const viewportData = _ref34.viewports.viewportSpecificData;

    const displaySets = OHIF.utils.studyMetadataManager.all(null).find(x => x._studyInstanceUID === activeTab)._displaySets;

    const activeThumbnailIndexes = Object.keys(viewportData).map(function (key) {
      return displaySets.findIndex(x => x.instanceNumber === viewportData[key].instanceNumber);
    });

    const thumbnailIndexMin = Math.min(...activeThumbnailIndexes);

    const paginationOutOfBounds = ((thumbnailIndexMin - layoutCount) < 0);
    const thumbnailIndexToStartAt = (paginationOutOfBounds) ? 0 : (thumbnailIndexMin - layoutCount);

    // Add frames to fill the layout.
    let newDisplaySets = {};

    const enabledElements = cornerstone.getEnabledElements();
    for (let i = 0; i < layoutCount; i++) {
      const nextIndex = thumbnailIndexToStartAt + i;

      const viewportCurrent = viewportData[i];
      const viewportNext = displaySets[nextIndex];

      if (viewportCurrent.displaySetInstanceUid === viewportNext.displaySetInstanceUid) {
        newDisplaySets[i] = viewportCurrent;
        continue;
      }

      const newDisplaySet = JSON.parse(JSON.stringify(viewportNext));

      if (enabledElements.length > i) {
        var element = enabledElements[i].element;
        //var element = _getActiveViewportEnabledElement(viewportData, i);

        cornerstoneTools.stopClip(element);
        cornerstoneTools.clearToolState(element, 'stack');

        newDisplaySet.dom = element;
      }

      // Setting these fields allows us to keep track of if cine was playing when navigating images in
      // OHIFCornerstoneViewport - setStateFromProps and ConnectedCornerstoneViewport - onElementEnabled.
      newDisplaySet.cine = {};
      // We use last cine's isPlaying to determine if the cine is still playing or not.
      newDisplaySet.cine.isPlaying = (viewportCurrent.cine && viewportCurrent.cine.isPlaying);
      newDisplaySet.cine.cineFrameRate = (newDisplaySet.frameRate) || 30;

      newDisplaySets[i] = newDisplaySet;
    }

    const newLayout = {
      numRows: _ref34.viewports.numRows,
      numColumns: _ref34.viewports.numColumns,
      viewports: _ref34.viewports.layout.viewports,
    };

    // Fix for going next on last instance.
    //
    // Only execute if we have new frames to add.
    if (Object.keys(newDisplaySets).length > 0) {
      handleClearCacheOnPagination();

      // Scroll to active thumbnail on sidebar.
      document.getElementById(`scrollable-study-thumbnails-${activeTab}`).scrollTop =
        document.getElementById(newDisplaySets[0].displaySetInstanceUid)
          .offsetTop - 87.5;

      store.dispatch(redux.setViewportLayoutAndData(newLayout, newDisplaySets));
    }
  },
  clearAnnotations: ({ viewports }) => {
    const element = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );
    if (!element) {
      return;
    }

    const enabledElement = cornerstone.getEnabledElement(element);
    if (!enabledElement || !enabledElement.image) {
      return;
    }

    const {
      toolState,
    } = cornerstoneTools.globalImageIdSpecificToolStateManager;
    if (
      !toolState ||
      toolState.hasOwnProperty(enabledElement.image.imageId) === false
    ) {
      return;
    }

    const imageIdToolState = toolState[enabledElement.image.imageId];

    const measurementsToRemove = [];

    Object.keys(imageIdToolState).forEach(toolType => {
      const { data } = imageIdToolState[toolType];

      data.forEach(measurementData => {
        const { _id, lesionNamingNumber, measurementNumber } = measurementData;
        if (!_id) {
          return;
        }

        measurementsToRemove.push({
          toolType,
          _id,
          lesionNamingNumber,
          measurementNumber,
        });
      });
    });

    measurementsToRemove.forEach(measurementData => {
      OHIF.measurements.MeasurementHandlers.onRemoved({
        detail: {
          toolType: measurementData.toolType,
          measurementData,
        },
      });
    });
  },
  nextImage: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    scroll(enabledElement, 1);
  },
  previousImage: ({ viewports }) => {
    const enabledElement = _getActiveViewportEnabledElement(
      viewports.viewportSpecificData,
      viewports.activeViewportIndex
    );

    scroll(enabledElement, -1);
  },
};

const definitions = {
  rotateViewportCW: {
    commandFn: actions.rotateViewport,
    storeContexts: ['viewports'],
    options: { rotation: 90 },
  },
  rotateViewportCCW: {
    commandFn: actions.rotateViewport,
    storeContexts: ['viewports'],
    options: { rotation: -90 },
  },
  invertViewport: {
    commandFn: actions.invertViewport,
    storeContexts: ['viewports'],
    options: {},
  },
  flipViewportVertical: {
    commandFn: actions.flipViewportVertical,
    storeContexts: ['viewports'],
    options: {},
  },
  flipViewportHorizontal: {
    commandFn: actions.flipViewportHorizontal,
    storeContexts: ['viewports'],
    options: {},
  },
  scaleUpViewport: {
    commandFn: actions.scaleViewport,
    storeContexts: ['viewports'],
    options: { direction: 1 },
  },
  scaleDownViewport: {
    commandFn: actions.scaleViewport,
    storeContexts: ['viewports'],
    options: { direction: -1 },
  },
  fitViewportToWindow: {
    commandFn: actions.scaleViewport,
    storeContexts: ['viewports'],
    options: { direction: 0 },
  },
  resetViewport: {
    commandFn: actions.resetViewport,
    storeContexts: ['viewports'],
    options: {},
  },
  clearAnnotations: {
    commandFn: actions.clearAnnotations,
    storeContexts: ['viewports'],
    options: {},
  },
  nextImage: {
    commandFn: actions.nextImage,
    storeContexts: ['viewports'],
    options: {},
  },
  previousImage: {
    commandFn: actions.previousImage,
    storeContexts: ['viewports'],
    options: {},
  },
  doubleClickThumbnail: {
    commandFn: actions.doubleClickThumbnail,
    storeContexts: ['viewports'],
    options: {}
  },
  // TODO: First/Last image
  // Next/Previous series/DisplaySet
  nextViewportDisplaySet: {
    commandFn: actions.updateNextViewportDisplaySet,
    storeContexts: ['viewports'],
    options: {},
  },
  previousViewportDisplaySet: {
    commandFn: actions.updatePreviousViewportDisplaySet,
    storeContexts: ['viewports'],
    options: {},
  },
  // TOOLS
  setToolActive: {
    commandFn: actions.setToolActive,
    storeContexts: [],
    options: {},
  },
};

function locateStudyInstanceUid(displaySetInstanceUid) {
  const studyMetadataManager = OHIF.utils.studyMetadataManager.all(null);

  for (let i = 0; i < studyMetadataManager.length; i++) {
    const study = studyMetadataManager[i];

    for (let j = 0; j < study._displaySets.length; j++) {
      const displaySet = study._displaySets[j];

      if (displaySet.displaySetInstanceUid === displaySetInstanceUid) {
        return study._studyInstanceUID;
      }
    }
  }
}

function handleClearCacheOnPagination() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    cornerstoneTools.requestPoolManager.clearRequestStack('prefetch-force-stop');
    window.cornerstone.imageCache.purgeCache();
  }
}

/**
 * Grabs `dom` reference for the enabledElement of
 * the active viewport
 */
function _getActiveViewportEnabledElement(viewports, activeIndex) {
  const activeViewport = viewports[activeIndex] || {};
  return activeViewport.dom;
}

export default {
  actions,
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::MP4',
};
