import { CLEAR_VIEWER_DATA, SET_REPORT_WINDOW, SET_STUDY, SET_STUDY_CONVERSION_STATUS } from './../constants/ActionTypes.js';

const defaultState = {
  open: false,
  study: '',
  studyConversionStatusLastUpdated: null,
  studyConversionStatus: {},
  studyConversionStatusOriginal: {}
};

/**
 * @param {Object} [state=defaultState]
 * @param {Object} action
 */
const viewer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_VIEWER_DATA:
      return defaultState;
    case SET_REPORT_WINDOW:
      return Object.assign({}, state, { open: action.data.open });
    case SET_STUDY:
      return Object.assign({}, state, { study: action.data.study });
    case SET_STUDY_CONVERSION_STATUS:
      const { studyInstanceUid, currentStatus, originalStatus } = action.payload;

      // This is a workaround so we can easily identify changes
      // to the progress object without doing deep comparison.

      const lastUpdated = new Date().getTime();

      return {
        ...state,
        studyConversionStatusLastUpdated: lastUpdated,
        studyConversionStatus: {
          ...state.studyConversionStatus,
          [studyInstanceUid]: currentStatus
        },
        ...(originalStatus !== null && {
          studyConversionStatusOriginal: {
            ...state.studyConversionStatusOriginal,
            [studyInstanceUid]: originalStatus
          }
        })
      };
    default:
      return state;
  }
};

export default viewer;
