const statuses = {
  ERROR: 'Error',
  NOT_ALL_MP4s_EXIST: 'NotAllMp4sExist',
  ALL_MP4s_EXIST: 'AllMp4sExist'
}

export const getConversionStatusLegacyViewer = () => {
  return statuses.NOT_ALL_MP4s_EXIST;
}

export const getConversionStatusMp4Viewer = () => {
  return statuses.ALL_MP4s_EXIST;
}

export const isMp4Viewer = (conversionStatus) => {
  return conversionStatus === statuses.ALL_MP4s_EXIST;
}
